import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SerImg from '../../asset/image/Services.jpg'
import '../Services/Services.css'
import '../../asset/css/Bootstrap.min.css'

import AppUrl from '../../AppUrl/AppUrl'
import RestApi from '../../AppUrl/RestApi'
import parse from 'html-react-parser';

export class Services extends Component {
    constructor(){
        super();
        this.state={
            data:[]
        }
    }
    componentDidMount()
    {
      RestApi.GetRequest(AppUrl.HomeServices).then(result=>{
        this.setState({data:result})
      });
    }
  render() {
    let $imgPath = 'images/';
    const List = this.state.data;
        const View = List?.map(List=>(
            <Col lg={4} md={6} sm={12}>
            <div className='serviceCard text-center'>
                {/*<img src={$imgPath+List.service_logo} className='ServicesIcon ' />*/}
                <img src={List.service_logo} className='ServicesIcon ' />
                <h4 className='serviceName'>{List.service_name}</h4>
                <p className='serviceDescription'>{parse(List.service_description)}</p>
            </div>
        </Col>
        ));
    return (
        <Fragment>
            <Container className='text-center'>
                <h1 className='serviceMainTitle'>الخدمات</h1>
                <div className='bottom'></div>
                <Row>
                    {View}
                </Row>
            </Container>
        </Fragment>
    )
  }
}

export default Services
