import React, { Component } from 'react'
import { Fragment } from 'react'
import HomePage from '../pages/HomePage'
import AboutPage from "../pages/AboutPage";
import ServicePage from "../pages/ServicePage";
import CoursesPage from "../pages/CoursesPage";
import ProjectPage from "../pages/ProjectPage";
import ContactPage from "../pages/ContactPage";
import ProjectDetailsPage from '../pages/ProjectDetailsPage';
import {
    BrowserRouter as Router,
    Route, Routes
} from "react-router-dom";
import CourseDetailsPage from '../pages/CourseDetailsPage';


export class AppRouter extends Component {
    render() {
        return (
            <Fragment>

                <Routes base="/">
                    <Route path="/" element={<HomePage />} />
                    <Route path="/About" element={<AboutPage />} />
                    <Route path="/AllCourses" element={<CoursesPage />} />
                    <Route path="/AllProject" element={<ProjectPage />} />
                    <Route path="/AllServices" element={<ServicePage />} />
                    <Route path="/Contact" element={<ContactPage />} />
                    <Route path="/ProjectDetailsPage/:ProjectId" element={<ProjectDetailsPage />} />
                    <Route path="/CourseDetailsPage/:CourseId" element={<CourseDetailsPage />} />
                    

                </Routes>


            </Fragment>
        )
    }
}

export default AppRouter
